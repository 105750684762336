import Rails from "@rails/ujs"
import $ from 'jquery'
import "bootstrap"
import Blacklight from '../blacklight'
import '../analytics' // GA4 is included elsewhere and the data from this is probably being discarded

if (!window._rails_loaded) Rails.start();

Blacklight.onLoad(function() {

    // enable all tooltips
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    });

    // Remove thumbnail img if thumbnail fails to load
    $('img.thumbnail').on('error', function(){
        this.remove();
    });

    // handling for secondary submit button to advanced search form
    var $auxAdvSubmit = $('button.auxiliary-advanced-search-submit');
    $auxAdvSubmit.click(function() {
        $('form.advanced').submit()
    });
    $auxAdvSubmit.removeClass('hide');

    // Handle switching search types
    var searchPrefix = 'Search ';
    $('.search-panel .dropdown-menu').find('a').click(function(e) {
        e.preventDefault();
        var $this = $(this);
        $('#search-type').text(searchPrefix + $this.text());
        $('input[name="search_field"]').val($this.data('search-field'))
    });

    // support deeplinking to tab content
    var url = window.location.href;
    if (url.indexOf("#") > 0){
        var activeTab = url.substring(url.indexOf("#") + 1);
        $('.nav[role="tablist"] a[href="#' + activeTab + '"]').tab('show');
    }

    $('a[role="tab"]').click(function(e) {
        var hash = $(this).attr("href");
        var newUrl = url.split("#")[0] + hash;
        history.replaceState(null, null, newUrl);
    });
    $('#close-harmful-content-modal').click(function() {
        $(this).closest(".modal").modal("hide");
        $('.modal-backdrop').css( "display", "none")
    });

    var modalOpened = getCookie("modal_open");
    if ((modalOpened !== "yes") && (window.location.pathname === '/')) {
        $('.harmful-content-modal').modal({
            open: true
        })
        modalOpened = "yes";
        setCookie("modal_open", modalOpened, 30);
    }
});

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}