// Copied from Stanford SearchWorks: https://github.com/sul-dlss/SearchWorks/blob/9e37247894ae1d8547b60858c9e86ac9908f6a54/app/assets/javascripts/analytics.js
// Inspired by and modified from http://railsapps.github.io/rails-google-analytics.html

window.GoogleAnalytics = (function() {
    function GoogleAnalytics() {}

    GoogleAnalytics.load = function() {
        var firstScript, ga;
        window._gaq = [];
        GoogleAnalytics.analyticsId = GoogleAnalytics.getAnalyticsId();
        window._gaq.push(["_setAccount", GoogleAnalytics.analyticsId]);
        ga = document.createElement("script");
        ga.type = "text/javascript";
        ga.async = true;
        ga.src = ("https:" === document.location.protocol ? "https://ssl" : "http://www") + ".google-analytics.com/ga.js";
        firstScript = document.getElementsByTagName("script")[0];
        firstScript.parentNode.insertBefore(ga, firstScript);
    };

    GoogleAnalytics.trackPageview = function(url) {
        if (!GoogleAnalytics.isLocalRequest()) {
            if (url) {
                window._gaq.push(["_trackPageview", url]);
            } else {
                window._gaq.push(["_trackPageview"]);
            }
            return window._gaq.push(["_trackPageLoadTime"]);
        }
    };

    GoogleAnalytics.isLocalRequest = function() {
        return GoogleAnalytics.documentDomainIncludes("local");
    };

    GoogleAnalytics.documentDomainIncludes = function(str) {
        return document.domain.indexOf(str) !== -1;
    };

    GoogleAnalytics.getAnalyticsId = function() {
        return $("[data-analytics-id]").data('analytics-id');
    };

    return GoogleAnalytics;

})();

Blacklight.onLoad(function(){
    GoogleAnalytics.load();
    if (GoogleAnalytics.analyticsId){
        GoogleAnalytics.trackPageview();
    }
});